import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";

const PrivacyPolicy = () => {
  const lang = "de";

  return (
    <Layout
      seoTitle="Datenschutzerklärung"
      lang={lang}
      translationPL="/polityka-prywatnosci/"
      translationEN="/en/privacy-policy/"
    >
      <SmallBanner title="Datenschutzerklärung" lang={lang} />

      <section className="default-main">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <p className="bold">
                1. Verantwortlicher für personenbezogene Daten
              </p>
              <p>
                Palettenwerk Kozik Sp. z o.o., ul. Przemysłowa 219, 34-240
                Jordanów, KRS: 0000966492 (nachstehend: PalettenWerk genannt).
              </p>
              <p className="bold">2. Kontaktangaben</p>
              <p>
                Das Unternehmen PalettenWerk können Sie per E-Mail:
                palety@palettenwerk.pl, unter der Telefonnummer +48 883 323 708
                oder schriftlich unter: Palettenwerk Kozik Sp. z o.o., ul.
                Przemysłowa 219, 34-240 Jordanów kontaktieren. PalettenWerk hat
                keinen Datenschutzbeauftragten bestellt.
              </p>
              <p className="bold">
                3. Zweck der Verarbeitung und rechtliche Grundlage der
                Verarbeitung
              </p>
              <ul>
                <li>
                  Ihre Daten werden zum Zweck der Zusendung zusätzlicher
                  kommerzieller Informationen über das Angebot von PalettenWerk
                  verarbeitet. Die Angabe der Daten ist freiwillig und
                  ermöglicht den Erhalt der Handelsinformation - die
                  Rechtgrundlage der Datenverarbeitung ist Ihre Einwilligung,
                  unter der Bedingung, dass diese Einwilligung von Ihnen erteilt
                  wurde; falls die Einwilligung nicht erteilt wurde, werden die
                  personenbezogenen Daten nicht zu diesem Zweck verarbeitet.
                </li>
                <li>
                  Ihre Daten werden zum Zwecke der Direktvermarktung der eigenen
                  Produkte und Dienstleistungen von PalettenWerk verarbeitet -
                  die Rechtsgrundlage für die Datenverarbeitung bildet Ihre
                  Einwilligung, sofern Sie diese erteilt haben; falls die
                  Einwilligung nicht erteilt wurde, werden die personenbezogenen
                  Daten zu diesem Zweck nicht verarbeitet.
                </li>
                <li>
                  Ihre Daten werden zwecks Erbringung von Dienstleistungen wie
                  z.B. die Teilnahme an Webinaren verarbeitet - die
                  Rechtsgrundlage für die Datenverarbeitung bildet Ihre
                  Einwilligung, sofern Sie diese erteilt haben; falls die
                  Einwilligung nicht erteilt wurde, werden die personenbezogenen
                  Daten zu diesem Zweck nicht verarbeitet.
                </li>
                <li>
                  Ihre Daten werden zum Zweck der Rekrutierung für eine
                  bestimmte Stelle verarbeitet - die Rechtsgrundlage für die
                  Datenverarbeitung bildet Ihre Einwilligung, die Sie beim
                  Ausfüllen des Bewerbungsformulars (Bewerbung für eine
                  bestimmte Stelle) erteilt haben.
                </li>
              </ul>
              <p className="bold">4. Datenaufbewahrungsfrist</p>
              <p>
                Die Daten werden über einen Zeitraum von 2 Jahren oder bis zum
                Moment des Widerrufes Ihrer Einwilligung zur Verarbeitung
                personenbezogener Daten zu Marketingzwecken verarbeitet.
              </p>
              <p className="bold">5. Empfänger von Daten</p>
              <p>
                Ihre Daten können an Stellen übermittelt werden, die im Auftrag
                von PalettenWerk personenbezogene Daten verarbeiten,
                insbesondere an IT-Dienstleister und Buchhaltungsbüros - diese
                Stellen werden die Daten auf der Grundlage eines Vertrags mit
                PalettenWerk und ausschließlich nach den Anweisungen von
                PalettenWerk verarbeiten.
              </p>
              <p className="bold">
                6. Übermittlung personenbezogener Daten außerhalb des
                Europäischen Wirtschaftsraums
              </p>
              <p>
                Ihre Daten werden nicht an die Empfänger übermittelt, die sich
                in den Staaten jenseits des Europäischen Wirtschaftsraumes
                befinden.
              </p>
              <p className="bold">7. Rechte der betroffenen Person</p>
              <p>Sie haben das Recht:</p>
              <ul>
                <li>des Zugangs zu Ihren Daten,</li>
                <li>der Forderung Ihrer Berichtigung,</li>
                <li>der Forderung Ihrer Entfernung,</li>
                <li>der Forderung der Einschränkung ihrer Verarbeitung,</li>
                <li>des Widerrufes der Einwilligung,</li>
                <li>
                  das Recht auf Datenübertragbarkeit, d.h. Ihre
                  personenbezogenen Daten von PalettenWerk in einem gängigen
                  Dateiformat zu erhalten,
                </li>
                <li>
                  Einlegung der Beschwerde beim Aufsichtsorgan, das sich mit dem
                  Schutz personenbezogener Daten befasst.
                </li>
              </ul>
              <p>
                Zur Ausübung dieser Rechte wenden Sie sich bitte an
                PalettenWerk. Das kann z.B. durch eine Anfrage an die
                Sitzadressevon PalettenWerk oder per E-Mail an
                palety@palettenwerk.pl erfolgen.
              </p>
              <p className="bold">8. Freiwillige Angabe von Daten</p>
              <p>
                Die Angabe der personenbezogenen Daten zu Marketingzwecken ist
                freiwillig. Die Angabe der personenbezogenen Daten zum Zwecke
                der Prüfung einer eventuellen Reklamation ist freiwillig, doch
                wird ihre Nichtangabe die Aufnahme und Prüfung der Reklamation
                unmöglich machen.
              </p>
              <p className="bold">9. Ergriffene Datenschutzvorkehrungen</p>
              <ul>
                <li>
                  Der Datenschutz erfolgt in Übereinstimmung mit den
                  Anforderungen der allgemein geltenden Gesetze. Die Daten
                  werden auf sicheren Servern gespeichert.
                </li>
                <li>
                  Wir respektieren Ihr Recht auf Privatsphäre und sorgen für die
                  Datensicherheit. Zu diesem Zweck wird u.a. ein sicheres
                  Verschlüsselungsprotokoll (SSL) verwendet.
                </li>
                <li>
                  Wir haben Datenverschlüsselung und Zugangskontrolle
                  eingeführt, um die Auswirkungen möglicher Verletzungen der
                  Datensicherheit zu minimieren.
                </li>
              </ul>
              <p className="bold">10. Cookie-Dateien</p>
              <ul>
                <li>
                  Die Website pallettenwerk.pl verwendet Cookies. Das sind
                  kleine Testdateien, die duch den Webserver verschickt und
                  durch die Computer-Software des Browsers aufbewahrt werden.
                  Wenn der Browser erneut mit der Seite verbindet, erkennt die
                  Webseite die Art des Gerätes wieder, von dem aus sich der
                  Nutzer verbindet. Die Parameter erlauben das Ablesen von darin
                  enthaltenen Informationen nur dem Server, der sie geöffnet
                  hat. Cookies erleichtern somit die Nutzung früher besuchter
                  Webseiten. Die darin aufbewahrten Informationen betreffen die
                  IP-Adresse, den Typ des verwendeten Browsers, die Sprache, die
                  Art des Betriebssystems, den Lieferanten der
                  Internetdienstleistungen, Informationen über die Zeit und das
                  Datum, den Ort und Informationen, die an die Webseite mit
                  Hilfe des Kontaktformulars verschickt werden.
                </li>
                <li>
                  Die erhobenen Daten werden zur Überwachung und Prüfung
                  verwendet, wie die Benutzer unsere Websites nutzen, um den
                  Betrieb des Service durch effizientere und störungsfreie
                  Navigation zu verbessern. Die Überwachung der Information über
                  die Nutzer vollbringen wir mit Hilfe des Werkzeugs Google
                  Analitics, welches das Verhalten des Nutzers auf der Seite
                  registriert. Cookie identifiziert den Nutzer, was die
                  Anpassung des Inhalts der Webseite, die er nutzt, an seine
                  Bedürfnisse erlaubt. Indem seine Präferenzen gemerkt werden,
                  wird eine entsprechende Anpassung der an ihn gerichteten
                  Werbungen möglich. Wir verwenden Cookies, um den höchsten
                  Komfort unserer Website zu gewährleisten, und die gesammelten
                  Daten werden nur innerhalb von PalettenWerk zur Optimierung
                  unseres Betriebs verwendet.
                </li>
                <li>
                  Wir verwenden folgende Cookies auf unserer Website:
                  <br />
                  <ul>
                    <li>
                      "notwendige" Cookie-Dateien, die die Nutzung der im Rahmen
                      des Services zugänglichen Dienstleistungen ermöglichen,
                      z.B. beglaubigende Cookie-Dateien, die für
                      Dienstleistungen verwendet werden, die eine Beglaubigung
                      im Rahmen des Services erfordern;
                    </li>
                    <li>
                      Cookie-Dateien, die der Gewährleistung der Sicherheit
                      dienen, die z.B. zur Aufdeckung der Missbräuche im Bereich
                      der Beglaubigungen im Rahmen des Services verwendet
                      werden;
                    </li>
                    <li>
                      "Leistungs-Cookie-Dateien", die das Sammeln von
                      Informationen über die Art der Nutzung der Internetseiten
                      des Services ermöglichen;
                    </li>
                    <li>
                      "Werbung-Cookie-Dateien", die die Lieferung den Nutzern
                      der Werbungsihalte ermöglichen, die mehr an ihre
                      Interessen angepasst sind.
                    </li>
                  </ul>
                </li>
                <li>
                  Der Benutzer hat jederzeit die Möglichkeit, die Option Sammeln
                  von Cookies zu deaktivieren oder wiederherzustellen, indem er
                  die Einstellungen in seinem Browser ändert. Die Anleitung zur
                  Verwaltung der Cookie-Dateien ist auf der folgenden Seite
                  zugänglich: http://www.allaboutcookies.org/manage-cookies
                </li>
                <li>
                  Zusätzliche personenbezogene Daten, wie z.B. die
                  E-Mail-Adresse, werden nur dort erhoben, wo der Benutzer durch
                  das Ausfüllen des Formulars dies ausdrücklich eingewilligt
                  hat. Die obigen Daten behalten wir und verwenden wir nur für
                  die Belange, die für die Ausführung der gegebenen Funktion
                  notwendig sind.
                </li>
              </ul>
              <p>
                Mehr zu Cookies finden Sie in unserer{" "}
                <Link className="color-btn--main" to="/de/cookie-politik/">
                  Cookie-Politik.
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PrivacyPolicy;
